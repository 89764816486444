.product-page {
	margin: 0;
	position: relative;
	min-height: 100vh;
	padding-bottom: 300px;
}

.product-page .content-split {
	position: relative;
	/* min-height: 100vh; */
}


/* CONTENT */
.product-page .product-info-container {
	box-sizing:border-box;
	width: calc(100vw / 2);
	/* min-height: 100vh; */
	margin-left: calc(100vw / 2);
	max-width: calc(100vw / 2);
	padding: 230px 150px 0 150px;
	/* margin-top: calc(100vh / 2 - 128px - (369px / 2));  */
}

.product-page .product-info-container .brand {
	margin: 0;
	font-weight: 700;
	opacity: 0.5;
	font-size: 13px;
	text-transform: uppercase;
	margin-top: 76px;
}

.product-page .product-info-container .title {
	margin: 10px 0 0 0;
	font-weight: 800;
	font-size: 22px;
}

.product-page .product-info-container .price {
	margin: 44px 0 0 0;
	font-weight: 700;
}

.product-page .product-info-container .sale-price {
	font-size: 24px;
	background-image: linear-gradient(133deg, #7EBAE8 0%, #4A90E2 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.product-page .product-info-container .original-price {
	margin-left: 10px;
	font-size: 13px;
	text-decoration: line-through;
	opacity: 0.5;
}

.product-page .product-info-container .deal-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 220px;
	height: 48px;
	background: rgb(154,182,243);
	background: linear-gradient(133deg, #89C7EF 0%, #4A90E2 100%);
	color: white;
	font-weight: 700;
	font-size: 13px;
	margin-top: 25px;
	border-radius: 13px;
	transition: 0.25s;
}

.product-page .product-info-container .deal-button p {
	width: 220px;
	height: 48px;
	background: rgb(154,182,243);
	background: linear-gradient(133deg, rgba(154,182,243,1) 0%, rgba(97,127,227,1) 100%);
	color: white;
	font-weight: 700;
	font-size: 13px;
	border-radius: 13px;
	transition: 0.25s;
}

.product-page .product-info-container .deal-button:hover {
	/* border-radius: 0px; */
	letter-spacing: 2px;
}

.product-page .product-info-container .seperate-line {
	background-color: white;
	margin: 100px 0;
	width: calc(100vw / 2);
	height: 3px;
	margin-left: -150px;
}

/* SHOP INFO */
.product-page .info-wrapper {
	display: inline-flex;
	flex-direction: column;
	gap: 24px;
}

.product-page .info-v-stack .title {
	font-weight: 600;
	font-size: 10px;
	letter-spacing: 2.33px;
	margin: 0 0 4px 0;
}

.product-page .info-v-stack .name {
	font-weight: 700;
	font-size: 14px;
	margin: 0;
	text-transform: uppercase;
}

/* DESCRIPTION */
.product-page .product-info-container .description {
	opacity: 0.4;
	font-size: 15px;
	line-height: 22px;
}

/* SELLING POINTS */
.product-page .product-info-container .selling-point-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.product-page .product-info-container .dot {
	background-color: #202020;
	opacity: 0.4;
	width: 5px;
	height: 5px;
	border-radius: 2.5px;
}

.product-page .product-info-container .selling-point-wrapper p {
	opacity: 0.4;
	font-size: 15px;
	/* line-height: 22px; */
	margin: 8px 0 8px 10px;
}


/* IMAGE */
.product-page .image-container {
	width: calc(100vw / 2);
	height: 100vh;
	background-color: white;
	position: fixed;
	/* margin-top: 0;
	margin-left: 0; */
	left: 0; top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-page .image-container img {
	width: calc(100vw / 2 - 400px);
	height: calc(100vw / 2 - 400px);
	max-height: calc(100vh - 600px);
	min-height: 300px;
	object-fit: contain;
}


@media screen and (min-width: 0px) and (max-width: 1950px) {
	.product-page .product-info-container {
		padding: 230px 100px 0 100px;
		/* padding-top: calc(100vh / 2 - (369px / 2)); */
	}

	.product-page .product-info-container .seperate-line {
		margin-left: -100px;
	}
}

@media screen and (min-width: 0px) and (max-width: 1450px) {
	.product-page .product-info-container {
		padding: 230px 60px 0 60px;
		/* padding-top: calc(100vh / 2 - (369px / 2)); */
	}

	.product-page .product-info-container .seperate-line {
		margin-left: -60px;
	}
}


@media screen and (min-width: 0px) and (max-width: 1080px) {
	.product-page .content-split {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.product-page .product-info-container {
		box-sizing: border-box;
		width: 100vw;
		max-width: 100vw;
		padding: 0px 100px 0 100px;
		margin-left: 0;
		/* margin-top: calc(100vh / 2 - 128px - (369px / 2)); */
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.product-page .product-info-container .title {
		text-align: center;
	}

	.product-page .product-info-container .seperate-line {
		margin-left: 0px;
	}

	.product-page .product-info-container .selling-point-wrapper p {
		text-align: center;
		margin-left: 0;
	}

	.product-page .product-info-container .dot {
		display: none;
	}

	/* IMAGE */
	.product-page .image-container {
		width: 100vw;
		height: 591px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 128px;
	}

	.product-page .image-container img {
		width: calc(100vw - 100px);
		height: calc(100vw - 100px);
		max-height: 391px;
		min-height: 300px;
	}

	/*  */
	.product-page .info-section {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	/* SHOP INFO */
	.product-page .info-wrapper {
		display: inline-flex;
		flex-direction: column;
		gap: 24px;
		align-items: center;
	}

	/* DESCRIPTION */
	.product-page .product-info-container .description {
		opacity: 0.4;
		font-size: 15px;
		line-height: 22px;
		text-align: center;
	}
}


@media screen and (min-width: 0px) and (max-width: 760px) {
	.product-page {
		padding-bottom: 200px;
	}

	.product-page .product-info-container {
		padding: 0px 30px 0 30px;
		/* padding-top: calc(100vh / 2 - (369px / 2)); */
	}

	/* IMAGE */
	.product-page .image-container {
		width: 100vw;
		height: 100vw;
		max-height: 400px;
		/* padding-top: 128px; */
	}

	.product-page .image-container img {
		width: calc(100vw - 100px);
		height: calc(100vw - 100px);
		max-height: 300px;
		min-height: 20px;
	}

	/*  */
	.product-page .product-info-container .brand {
		margin-top: 56px;
	}

	/*  */
	.product-page .product-info-container .seperate-line {
		margin: 80px 0;
		width: calc(100vw / 2);
	}
}