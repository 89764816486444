.Filter .side-container {
	background-color: white;
	width: 410px;
	height: 100vh;
	position: fixed;
	left: 0; top: 0;
	z-index: 100;
	/* transition: left 0.3s ease-out; */
}

.Filter .category-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/* width: fit-content; */
	padding-top: 121px;
	padding-left: 55px;
}

.Filter .gender-c {
	padding-top: 60px;
}

.Filter .category-container button {
	background: rgba(0, 0, 0, 0);
	margin-bottom: 30px;
	display: flex;
	align-items: center;
}

.Filter .category-container p {
	margin: 0;
	font-weight: 600;
	font: 15px;
	margin-left: 13px;
}

.Filter .select-cat {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


.Filter .overlay {
	background-color: rgba(1, 1, 1, 1);
	width: 100vw;
	height: 100vh;
	z-index: 99;
	position: fixed;
	top: 0;
}


@media screen and (min-width: 0px) and (max-width: 500px) {
	.Filter .side-container {
		width: 320px;
	}
}

@media screen and (min-width: 0px) and (max-width: 300px) {
	.Filter .side-container {
		width: 220px;
	}
}

@media screen and (min-width: 0px) and (max-width: 250px) {
	.Filter .side-container {
		width: 100px;
	}
}