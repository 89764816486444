

a {
  text-decoration: none;
  color: #282828;
	background-color: rgba(0, 0, 0, 0)
}

button:active {
  color: #282828;
}


button {
  padding: 0;
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Montserrat', sans-serif;
}