.side-menu .side-container {
	background-color: white;
	width: 410px;
	height: 100vh;
	position: fixed;
	left: 0; top: 0;
	z-index: 100;
}

.side-menu .category-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/* width: fit-content; */
	padding-top: 121px;
	padding-left: 55px;
}


.side-menu .overlay {
	background-color: rgba(1, 1, 1, 1);
	width: 100vw;
	height: 100vh;
	z-index: 99;
	position: fixed;
	top: 0;
	left: 0;
}

/* PAGES */
.side-menu .page-container {
	margin-top: 75px;
	position: relative;
}

.side-menu .page-container .row {
	background-color: rgb(255, 255, 255);
	box-sizing: border-box;
	height: 46px;
	width: 100%;
	padding: 0 27px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.side-menu .page-container .row p {
	font-weight: 500;
	font-size: 14px;
}

.side-menu .page-container .back { justify-content: flex-start; }

.side-menu .page-container .back p { margin-left: 15px; }

.side-menu .page-container .back img { transform: rotate(180deg); }

.side-menu .page-container .row:hover {
	background-color: rgb(0, 0, 0, 0.04);
}


@media screen and (min-width: 0px) and (max-width: 600px) {
	.side-menu .side-container {
		background-color: white;
		width: 330px;
		height: 100vh;
		position: fixed;
		left: 0; top: 0;
		z-index: 100;
	}	
}

@media screen and (min-width: 0px) and (max-width: 380px) {
	.side-menu .side-container {
		background-color: white;
		width: 270px;
		height: 100vh;
		position: fixed;
		left: 0; top: 0;
		z-index: 100;
	}	
}