.Footer {
	background-color: white;
	width: 100vw;
	height: 138px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: 0;
	z-index: 1;
}

.Footer .link-container {
	margin-left: 63px;
}

.Footer .link {
	opacity: 0.84;
	font-size: 10px;
	color: #202020;
	font-weight: 600;
	margin-right: 20px;
}

.Footer .instagram {
	background-image: url("../../assets/Instagram-Icon.svg");
	background-size: contain;
	width: 22px;
	height: 22px;
	opacity: 0.84;
	margin-right: 63px;
}