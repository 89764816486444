.impressum {
	position: relative;
	min-height: 100vh;
	padding-bottom: 250px;
}

.impressum .wrapper {
	/* display: flex;
	justify-content: center; */
	/* width: 100vw; */
	margin: 0 90px;
	margin-top: 70px;
}

.impressum .infos-wrapper {
	display: flex;
	align-items: center;
	/* margin: 100px; */
	width: calc(100vw - 200px);
	max-width: 500px;
	background-color: red;
}

.impressum .title {
	font-size: 17px;
	font-weight: 700;
	margin: 0;
	margin-top: 80px;
	margin-bottom: 30px;
}

.impressum .low {
	margin-top: 40px;
}

.impressum .content {
	font-size: 14px;
	font-weight: 600;
	margin-top: 9px;
	opacity: 0.5;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
	.impressum {
		margin-top: 180px;
		padding-bottom: 290px;
	}
}

@media screen and (min-width: 0px) and (max-width: 600px) {
	.impressum {
		margin-top: 130px;
		padding-bottom: 230px;
	}

	.impressum .wrapper {
		margin: 0 30px;
		margin-top: 70px;
	}
}