.OptionContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 180px;
	max-width: 1400px;
	width: calc(100vw - (6.3vw * 2));
}

.OptionContainer .filter-container {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);
}

.OptionContainer .filter-container img {
	width: 20px;
	height: 16px;
	object-fit: contain;
}

.OptionContainer .filter-container p {
	margin: 0;
	margin-left: 13px;
	font-weight: 700;
	font-size: 13px;
	color: #737474;
}

.OptionContainer .sortBy-container {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);	
}

.OptionContainer .sortBy-container p {
	margin: 0;
	margin-right: 13px;
	font-weight: 700;
	font-size: 13px;
	color: #737474;
}

.OptionContainer .sortBy-container select {
	width: 132px;
	height: 40px;
	background-color: white;
	border-radius: 8px;
	/* -webkit-appearance: none;
   	-moz-appearance: none;
   	appearance: none; */
	text-align: center;
	text-align-last: center;
	-moz-text-align-last: center;
	font-size: 11px;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	color: #737474;
}

.OptionContainer .sortBy-container select:focus {
	outline: none
  }



@media screen and (min-width: 0px) and (max-width: 1250px) {
	.OptionContainer {
		width: calc(100vw - (9vw * 2));
	}
}

@media screen and (min-width: 0px) and (max-width: 980px) {
	.OptionContainer {
		width: calc(100vw - (6.3vw * 2));
	}
}

@media screen and (min-width: 0px) and (max-width: 700px) {
	.OptionContainer {
		margin-top: 130px;
		width: calc(100vw - (5.6vw * 2));
	}
}