.CategorySection {
	height: 525px;
	background-size: cover;
	background-position-y: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	margin-top: 170px;
	position: relative;
}

.CategorySection h3 {
	color: white;
	font-size: 30px;
	font-weight: 700;
	z-index: 3;
	padding: 0 20px;
	text-align: center;
}

.CategorySection .bg-image {
	position: absolute;
	width: 100vw;
	height: 100%;
	object-fit: cover;
	z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 1200px) {
	.CategorySection {
		height: 425px;
	}
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
	.CategorySection {
		height: 325px;
		margin-top: 150px;
	}
}

@media screen and (min-width: 0px) and (max-width: 600px) {
	.CategorySection {
		height: 190px;
		/* margin-top: 51px; */
		margin-top: 90px;
	}

	.app-banner {
		height: 260px;
	}

	.CategorySection h3 {
		color: white;
		font-size: 20px;
	}
}