
.search-overlay {
	background-color: white;
	height: 417px;
	overflow: auto;
	width: 312px;
	position: fixed;
	border-radius: 13px;
	right: 63px;
}

.search-overlay .item-container {
	display: flex;
	margin: 30px 15px;
}

.search-overlay img {
	width: 61px;
	height: 61px;
	object-fit: contain;
}

.search-overlay .info-search-container {
	margin-left: 15px;
}

.search-overlay .brand {
	font-size: 10px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	opacity: 0.45;
}

.search-overlay .title {
	font-size: 13px;
	font-weight: 600;
	text-overflow: ellipsis; 
	overflow: hidden; 
	white-space: nowrap;
	width: calc(312px - 45px - 61px);
	margin: 0;
	margin-top: 2px;
}

.search-overlay .price {
	margin: 10px 0 0 0;
}


.search-overlay .sale-price {
	margin: 0;
	font-size: 12px;
	font-weight: 600;
	background-image: linear-gradient(133deg, rgba(154,182,243,1) 0%, rgba(97,127,227,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.search-overlay .original-price {
	font-size: 12px;
	color: #202020;
	text-decoration: line-through;
	margin-left: 9px;
	opacity: 0.8;
}

.search-overlay .all-res-button p {
	font-size: 13px;
	width: calc(312px - 30px);
	margin-left: 15px;
	height: 48px;
	background: #202020;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: 700;
	border-radius: 11px;
}


/* Min. 2 zeichen */
.search-overlay .center-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.search-overlay .center-container p {
	font-size: 12px;
}



@media screen and (min-width: 0px) and (max-width: 1080px) {
	.search-overlay {
		height: calc(100vh - 54px - 60px);
		width: 100vw;
		border-radius: 0px;
		right: 0px;
	}
	
	.search-overlay .item-container {
		display: flex;
		margin: 40px 15px;
	}
	
	.search-overlay img {
		width: 90px;
		height: 90px;
		object-fit: contain;
	}
	
	.search-overlay .info-search-container {
		margin-left: 15px;
	}
	
	.search-overlay .brand {
		font-size: 11px;
	}
	
	.search-overlay .title {
		font-size: 15px;
		width: calc(100vw - 45px - 90px);
	}
	
	.search-overlay .sale-price {
		margin-top: 15px;
		font-size: 15px;
	}
	
	.search-overlay .original-price {
		font-size: 12px;
	}

	.search-overlay .all-res-button p {
		width: calc(100vw - 30px);
		margin-bottom: 100px;
	}
}