.H-Home-Collection-Outer {
	position: relative;
}


/* NAV */
.H-Home-Collection-Outer .nav-container {
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100vw;
	/* z-index: 1; */
	background-color: rgba(0, 0, 0, 0);
}
.H-Home-Collection-Outer .nav-arrrow {
	position: absolute;
	width: 70px;
	height: 70px;
	border-radius: 35px;
	background-color: #202020;
	backdrop-filter: blur(0px);
	margin-top: calc(402px / 2 + 35px - 35px);
	background: rgba(201, 201, 201, 0);
	-webkit-transition: 0.2s ease-out;
	-moz-transition: 0.2s ease-out;
	-o-transition: 0.2s ease-out;
	transition: 0.2s ease-out;
}

.H-Home-Collection-Outer .nav-right {
	left: 63px;
}

.H-Home-Collection-Outer .nav-left {
	right: 63px;
}

.H-Home-Collection-Outer:hover .nav-arrrow  {
	background: rgba(201, 201, 201, 0.2);
	backdrop-filter: blur(10px);
}

.H-Home-Collection-Outer .nav-arrrow img {
	width: 14px;
	height: 24px;
	object-fit: contain;
	opacity: 0;
}

.H-Home-Collection-Outer:hover .nav-arrrow img  {
	opacity: 1;
}




/* H Collection */
.H-Home-Collection {
	display: flex;
	flex-wrap: nowrap; 
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.H-Home-Collection::-webkit-scrollbar {
    display: none;
}

.H-Home-Collection-Outer .newest {
	font-weight: 700;
	font-size: 14px;
	color: #202020;
	margin: 0;
	margin-left: calc((100vw - 1400px) / 2);
}


.H-Home-Collection-Outer .newest-link {
	margin: 0;
	display: flex;
	align-items: center;
	margin-top: 157px;
}

.H-Home-Collection-Outer .link-icon {
	width: 7px;
	height: 11px;
	object-fit: contain;
	margin-left: 13px;
}


.H-Home-Collection .outer-container {
	margin-top: 35px;
	flex: 0 0 auto;
	height: 402px;
	display: grid;
	grid-template-columns: repeat(auto-fill, 1fr);
	grid-template-rows: 402px;
	column-gap: 100px;
}

.H-Home-Collection .item {
	grid-row: 1/2;
	width: 275px;
	height: 402px;
	background-color: white;
	border-radius: 10px;
}

.H-Home-Collection .item:first-of-type {
	margin-left: calc((100vw - 1400px) / 2);
}

.H-Home-Collection .item:last-of-type {
	margin-right: calc((100vw - 1400px) / 2);
}

/* INFOS */
.H-Home-Collection .item-inner-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
}

.H-Home-Collection .image-container {
	width: 275px;
	height: 275px;
}

.H-Home-Collection .image-container img {
	width: calc(275px - 100px);
	height: calc(275px - 100px);
	object-fit: contain;
	margin: 55px;
}

.H-Home-Collection .brand {
	font-size: 12px;
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	margin-top: 23px;
	opacity: 0.45;
}

.H-Home-Collection .title {
	font-size: 14px;
	text-align: center;
	font-weight: 500;
	text-overflow: ellipsis; 
	overflow: hidden; 
	white-space: nowrap;
	width: calc(275px - 60px);
	margin: 0;
	margin-top: 6px;
	opacity: 0.76;
}

.H-Home-Collection .price {
	margin: 0;
	margin-top: 15px;
	letter-spacing: -0.3px;
}

.H-Home-Collection .sale-price {
	margin: 0;
	font-size: 16px;
	letter-spacing: -0.3px;
	font-weight: 600;
	background-image: linear-gradient(133deg, #89C7EF 0%, #4A90E2 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.H-Home-Collection .original-price {
	font-size: 13px;
	color: #202020;
	opacity: 0.32;
	text-decoration: line-through;
	margin-left: 8px;
	opacity: 0.35;
}



@media screen and (min-width: 0px) and (max-width: 1600px) {
	.H-Home-Collection .outer-container {
		height: 380px;
		grid-template-rows: 380px;
		padding: 0 100px 0 100px;
		column-gap: 100px;
	}

	.H-Home-Collection .item {
		width: 260px;
		height: 380px;
	}

	.H-Home-Collection .item:first-of-type {
		margin-left: 0;
	}
	
	.H-Home-Collection .item:last-of-type {
		margin-right: 0;
	}

	.H-Home-Collection-Outer .newest {
		margin-left: 100px;
	}

	.H-Home-Collection .image-container {
		width: 260px;
		height: 260px;
	}
	
	.H-Home-Collection .image-container img {
		width: calc(260px - 60px);
		height: calc(260px - 60px);
		margin: 30px;
	}
	
	.H-Home-Collection .brand {
		/* font-size: 11px; */
		margin-top: 5px;
	}
	
	.H-Home-Collection .title {
		width: calc(260px - 60px);
	}

	.H-Home-Collection .price {
		margin-top: 19px;
	}

	.H-Home-Collection .original-price {
	}

	/* NAV */
	.H-Home-Collection-Outer .nav-arrrow {
		width: 70px;
		height: 70px;
		border-radius: 35px;
		margin-top: calc(380px / 2 + 35px - 35px);
	}
}




@media screen and (min-width: 0px) and (max-width: 1200px) {
	.H-Home-Collection .outer-container {
		padding: 0 63px 0 63px;
		column-gap: 63px;
	}

	.H-Home-Collection-Outer .newest {
		margin-left: 63px;
	}
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
	.H-Home-Collection-Outer .newest-link {
		margin-top: 100px;
	}

	.H-Home-Collection .outer-container {
		height: 300px;
		grid-template-rows: 300px;
		padding: 0 30px 0 30px;
		column-gap: 30px;
	}

	.H-Home-Collection .item {
		width: 190px;
		height: 300px;
	}

	.H-Home-Collection .item:first-of-type {
		margin-left: 0;
	}
	
	.H-Home-Collection .item:last-of-type {
		margin-right: 0;
	}

	.H-Home-Collection-Outer .newest {
		margin-left: 30px;
	}

	.H-Home-Collection .image-container {
		width: 190px;
		height: 190px;
	}
	
	.H-Home-Collection .image-container img {
		width: calc(190px - 44px);
		height: calc(190px - 44px);
		margin: 22px;
	}
	
	.H-Home-Collection .brand {
		/* font-size: 11px; */
		margin-top: 0px;
		font-size: 12px;
	}
	
	.H-Home-Collection .title {
		width: calc(190px - 44px);
		margin-top: 2px;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;	 
		white-space: normal;
	}

	.H-Home-Collection .price {
		margin-top: 12px;
	}

	.H-Home-Collection .sale-price {
		font-size: 15px;
	}

	.H-Home-Collection .original-price {
		font-size: 11px;
	}

	/* NAV */
	.H-Home-Collection-Outer .nav-arrrow {
		width: 70px;
		height: 70px;
		border-radius: 35px;
		margin-top: calc(300px / 2 + 35px - 35px);
	}
}


@media screen and (min-width: 0px) and (max-width: 600px) {
	.H-Home-Collection-Outer .newest-link {
		margin-top: 50px;
	}

	.H-Home-Collection .outer-container {
		height: 238px;
		grid-template-rows: 238px;
		padding: 0 16px 0 16px;
		column-gap: 16px;
	}

	.H-Home-Collection .item {
		width: 151px;
		height: 238px;
	}

	.H-Home-Collection .item:first-of-type {
		margin-left: 0;
	}
	
	.H-Home-Collection .item:last-of-type {
		margin-right: 0;
	}

	.H-Home-Collection-Outer .newest {
		margin-left: 16px;
	}

	.H-Home-Collection .image-container {
		width: 151px;
		height: 151px;
	}
	
	.H-Home-Collection .image-container img {
		width: calc(151px - 36px);
		height: calc(151px - 36px);
		margin: 18px;
	}
	
	.H-Home-Collection .brand {
		/* font-size: 11px; */
		margin-top: 0px;
		font-size: 9px;
	}
	
	.H-Home-Collection .title {
		width: calc(151px - 36px);
		margin-top: 1px;
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;	 
		white-space: normal;
	}

	.H-Home-Collection .price {
		margin-top: 8px;
	}

	.H-Home-Collection .sale-price {
		font-size: 12px;
	}

	.H-Home-Collection .original-price {
		font-size: 10px;
	}

	/* NAV */
	.H-Home-Collection-Outer .nav-right {
		left: 40px;
	}
	
	.H-Home-Collection-Outer .nav-left {
		right: 40px;
	}

	.H-Home-Collection-Outer .nav-arrrow {
		width: 50px;
		height: 50px;
		border-radius: 25px;
		margin-top: calc(238px / 2 + 35px - 35px);
	}

	.H-Home-Collection-Outer .nav-arrrow img {
		width: 10px;
		/* height: 24px; */
		object-fit: contain;
		opacity: 0;
	}
	
	.H-Home-Collection-Outer:hover .nav-arrrow img  {
		opacity: 0.7;
	}
	
}