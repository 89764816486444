.Page404 {
	position: relative;
	min-height: 100vh;
}

.Page404 .wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.Page404 h1  {
	font-weight: 700;
	font-size: 25px;
	opacity: 0.9;
	margin-top: 90px;
}

.Page404 h1 .code {
	font-weight: 500;
	font-size: 40px;
}

.Page404 h2 {
	font-weight: 500;
	font-size: 15px;
	opacity: 0.6;
	text-align: center;
}

.Page404 .back {
	background-color: #202020;
	color: white;
	width: 300px;
	height: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 11px;
	font-size: 15px;
	font-weight: 700;
	margin-top: 50px;
}

@media only screen and (max-width: 750px) {
	.Page404 .wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 0 30px;
	}

	.Page404 h1  {
		margin-top: 150px;
	}

	.Page404 .back {
		background-color: #202020;
		color: white;
		width: 200px;
	}
}
