.Home {
	position: relative;
	min-height: 100vh;
	padding-bottom: 300px;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
	.Home {
		margin-top: 180px;
		padding-bottom: 290px;
	}
}

@media screen and (min-width: 0px) and (max-width: 600px) {
	.Home {
		margin-top: 130px;
		padding-bottom: 230px;
	}
}