@import url(https://fonts.googleapis.com/css?family=Montserrat:500,600,700,800&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F4F5F7; */
  background-color: rgba(244, 245, 247, 0.9);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
	font-family: 'Montserrat', sans-serif;
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border: none;
  }

button {
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	-webkit-tap-highlight-color: transparent;
}


a {
  text-decoration: none;
  color: #282828;
	background-color: rgba(0, 0, 0, 0)
}

button:active {
  color: #282828;
}


button {
  padding: 0;
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Montserrat', sans-serif;
}
.Home {
	position: relative;
	min-height: 100vh;
	padding-bottom: 300px;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
	.Home {
		margin-top: 180px;
		padding-bottom: 290px;
	}
}

@media screen and (min-width: 0px) and (max-width: 600px) {
	.Home {
		margin-top: 130px;
		padding-bottom: 230px;
	}
}
.side-menu .side-container {
	background-color: white;
	width: 410px;
	height: 100vh;
	position: fixed;
	left: 0; top: 0;
	z-index: 100;
}

.side-menu .category-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/* width: fit-content; */
	padding-top: 121px;
	padding-left: 55px;
}


.side-menu .overlay {
	background-color: rgba(1, 1, 1, 1);
	width: 100vw;
	height: 100vh;
	z-index: 99;
	position: fixed;
	top: 0;
	left: 0;
}

/* PAGES */
.side-menu .page-container {
	margin-top: 75px;
	position: relative;
}

.side-menu .page-container .row {
	background-color: rgb(255, 255, 255);
	box-sizing: border-box;
	height: 46px;
	width: 100%;
	padding: 0 27px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.side-menu .page-container .row p {
	font-weight: 500;
	font-size: 14px;
}

.side-menu .page-container .back { justify-content: flex-start; }

.side-menu .page-container .back p { margin-left: 15px; }

.side-menu .page-container .back img { transform: rotate(180deg); }

.side-menu .page-container .row:hover {
	background-color: rgb(0, 0, 0, 0.04);
}


@media screen and (min-width: 0px) and (max-width: 600px) {
	.side-menu .side-container {
		background-color: white;
		width: 330px;
		height: 100vh;
		position: fixed;
		left: 0; top: 0;
		z-index: 100;
	}	
}

@media screen and (min-width: 0px) and (max-width: 380px) {
	.side-menu .side-container {
		background-color: white;
		width: 270px;
		height: 100vh;
		position: fixed;
		left: 0; top: 0;
		z-index: 100;
	}	
}

.search-overlay {
	background-color: white;
	height: 417px;
	overflow: auto;
	width: 312px;
	position: fixed;
	border-radius: 13px;
	right: 63px;
}

.search-overlay .item-container {
	display: flex;
	margin: 30px 15px;
}

.search-overlay img {
	width: 61px;
	height: 61px;
	object-fit: contain;
}

.search-overlay .info-search-container {
	margin-left: 15px;
}

.search-overlay .brand {
	font-size: 10px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	opacity: 0.45;
}

.search-overlay .title {
	font-size: 13px;
	font-weight: 600;
	text-overflow: ellipsis; 
	overflow: hidden; 
	white-space: nowrap;
	width: calc(312px - 45px - 61px);
	margin: 0;
	margin-top: 2px;
}

.search-overlay .price {
	margin: 10px 0 0 0;
}


.search-overlay .sale-price {
	margin: 0;
	font-size: 12px;
	font-weight: 600;
	background-image: linear-gradient(133deg, rgba(154,182,243,1) 0%, rgba(97,127,227,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.search-overlay .original-price {
	font-size: 12px;
	color: #202020;
	text-decoration: line-through;
	margin-left: 9px;
	opacity: 0.8;
}

.search-overlay .all-res-button p {
	font-size: 13px;
	width: calc(312px - 30px);
	margin-left: 15px;
	height: 48px;
	background: #202020;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: 700;
	border-radius: 11px;
}


/* Min. 2 zeichen */
.search-overlay .center-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.search-overlay .center-container p {
	font-size: 12px;
}



@media screen and (min-width: 0px) and (max-width: 1080px) {
	.search-overlay {
		height: calc(100vh - 54px - 60px);
		width: 100vw;
		border-radius: 0px;
		right: 0px;
	}
	
	.search-overlay .item-container {
		display: flex;
		margin: 40px 15px;
	}
	
	.search-overlay img {
		width: 90px;
		height: 90px;
		object-fit: contain;
	}
	
	.search-overlay .info-search-container {
		margin-left: 15px;
	}
	
	.search-overlay .brand {
		font-size: 11px;
	}
	
	.search-overlay .title {
		font-size: 15px;
		width: calc(100vw - 45px - 90px);
	}
	
	.search-overlay .sale-price {
		margin-top: 15px;
		font-size: 15px;
	}
	
	.search-overlay .original-price {
		font-size: 12px;
	}

	.search-overlay .all-res-button p {
		width: calc(100vw - 30px);
		margin-bottom: 100px;
	}
}
a {
	cursor: pointer;
}
.HomeHeader {
	/* position: fixed; */
	z-index: 90;
	top: 0;
}

.HomeHeader .header-container {
	margin: 0;
	width: 100vw;
	padding: 43px 0;
	z-index: 99;
	position: relative;
	/* background-color: rgba(255, 255, 255, 0); */
}

/*  */
.HomeHeader .title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.HomeHeader .title-container img {
	object-fit: contain;
	width: 33px;
}

.HomeHeader .title-container h3 {
	margin: 0;
	font-size: 13px;
	margin-left: 15px;
	background-image: linear-gradient(-134deg, #467BE4 0%, #1F6CCE 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}


.HomeHeader .bottom-container {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100vw - 126px);
	/* margin-top: 26px; */
	margin-left: 63px;
}


/* LOGO */
.HomeHeader .nav-logo-wrapper {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 95;
}



/* LINKS */
.HomeHeader .links-container {
	display: inline-flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
	z-index: 96;
}

/* The container <div> - needed to position the dropdown content */
.HomeHeader .dropdown {
	position: relative;
	display: inline-block;
}

.HomeHeader .dropdown-cover-content {
	padding-top: 15px;
	display: none;
	position: absolute;
	min-width: 160px;
	left: 51%;
	transform: translateX(-51%);
}

.HomeHeader .dropdown-h-container {
	display: flex;
	border-radius: 13px;
	background-color: white;
}
  
.HomeHeader .dropdown-content {
	background-color: white;
	min-width: 160px;
	z-index: 100;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 13px;
	padding: 35px 0;
}

.HomeHeader .dropdown-content .gender-title {
	font-size: 10px;
	font-weight: 700;
	margin: 0;
	margin-bottom: 20px;
}

.HomeHeader .subLink {
	cursor: pointer;
	background-color: rgb(0, 0, 0, 0);
}

.HomeHeader .dropdown-content .subLink p {
	margin: 0 0 17px 0;
	font-size: 12px;
	font-weight: 700;
	/* margin-bottom: 17px; */
	opacity: 0.42;
	/* transition: 0.25s; */
	color: #282828;
}

.HomeHeader .dropdown-content .subLink:last-child p { margin-bottom: 0px;}
.HomeHeader .dropdown-content .subLink:hover p { opacity: 1}

/* Show the dropdown menu on hover */
.HomeHeader .dropdown:hover .dropdown-cover-content {display: block;}

.HomeHeader .links-container .link {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	font-size: 11px;
	font-weight: 700;
	border-radius: 11px;
}

.HomeHeader .links-container .link p {
	margin: 0;
	color: rgb(32, 32, 32, 0.4);
	padding: 14px 18.5px;
	border-radius: 11px;
	transition: ease-out 0.3s;
	text-transform: uppercase;
}

.HomeHeader .dropdown:hover .link p {
	color: rgb(32, 32, 32, 1);
	background-color: white;
}



/* Search */
/* INPUT */
.HomeHeader .link-input {
	border: 0;
	background-color: rgb(255, 255, 255, 0.7);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	height: 40px;
	width: 280px;
	border-radius: 9px;
	text-align: center;
	padding: 0 16px;
	font-weight: 600;
	font-size: 14px;
	color: #282828;
	font-family: 'Montserrat', sans-serif;
	z-index: 97;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ABABAB;
}

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ABABAB;
}

::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ABABAB;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ABABAB;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #ABABAB;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: #ABABAB;
 }

.HomeHeader input:focus {
	outline: none;
}




/* Icon Left */
.HomeHeader .DA-icon-left {
	object-fit: contain;
	width: 46px;
	height: 49px;
	margin-right: 40px;
}



.HomeHeader .toggler {
	cursor: pointer;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	background-color: transparent;
	border: 0 none;
	color: #fff;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	outline: 0;
	position: relative;
	transition: ease 200ms color;
	z-index: 1000;
	width: 25px;
	height: 14px;
  }
  
.HomeHeader .path {
	/* stroke: currentColor; */
	background-color: #282828;
	width: 25px;
	height: 4px;
	border-radius: 2px;
}



/* Mobile Search */
.HomeHeader .search-icon-button {
	z-index: 99;
	background-color: rgb(0, 0, 0, 0);
}

.HomeHeader .search-mobile-container {
	background-color: white;
	width: 100vw;
	height: 60px;
	display: flex;
	align-items: center;
	z-index: 80;
}

.HomeHeader .mobile-search-field {
	border: 0;
	background-color: #F2F2F2;
	height: 42px;
	width: calc(100vw - 15px - 90px);
	border-radius: 10px;
	padding: 0 13px;
	display: flex;
	align-items: center;
	margin: 0 10px 0 15px;
}

.HomeHeader .mobile-search-field img {
	width: 12.5px;
	height: 12.5px;
	object-fit: contain;
	opacity: 0.3;
}

.HomeHeader .mobile-search-input {
	border: 0;
	background-color: #F2F2F2;
	height: 42px;
	text-align: left;
	padding: 0 0 0 7px;
	font-weight: 500;
	font-size: 13px;
	color: #282828;
	font-family: 'Montserrat', sans-serif;
	z-index: 97;
	width: 100%;
}

.HomeHeader .cancel-search {
	font-size: 11px;
	margin-right: 15px;
	height: 100%;
	background-color: rgb(0, 0, 0, 0);
}



  @media screen and (min-width: 0px) and (max-width: 1080px) {
	  .link-input {
		  display: none;
	  }

	  .HomeHeader .bottom-container {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(100vw - 52px);
		/* margin-top: 26px; */
		margin-left: 27px;
		z-index: 90;
	}	
  }	
.H-Home-Collection-Outer {
	position: relative;
}


/* NAV */
.H-Home-Collection-Outer .nav-container {
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100vw;
	/* z-index: 1; */
	background-color: rgba(0, 0, 0, 0);
}
.H-Home-Collection-Outer .nav-arrrow {
	position: absolute;
	width: 70px;
	height: 70px;
	border-radius: 35px;
	background-color: #202020;
	-webkit-backdrop-filter: blur(0px);
	        backdrop-filter: blur(0px);
	margin-top: calc(402px / 2 + 35px - 35px);
	background: rgba(201, 201, 201, 0);
	transition: 0.2s ease-out;
}

.H-Home-Collection-Outer .nav-right {
	left: 63px;
}

.H-Home-Collection-Outer .nav-left {
	right: 63px;
}

.H-Home-Collection-Outer:hover .nav-arrrow  {
	background: rgba(201, 201, 201, 0.2);
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
}

.H-Home-Collection-Outer .nav-arrrow img {
	width: 14px;
	height: 24px;
	object-fit: contain;
	opacity: 0;
}

.H-Home-Collection-Outer:hover .nav-arrrow img  {
	opacity: 1;
}




/* H Collection */
.H-Home-Collection {
	display: flex;
	flex-wrap: nowrap; 
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.H-Home-Collection::-webkit-scrollbar {
    display: none;
}

.H-Home-Collection-Outer .newest {
	font-weight: 700;
	font-size: 14px;
	color: #202020;
	margin: 0;
	margin-left: calc((100vw - 1400px) / 2);
}


.H-Home-Collection-Outer .newest-link {
	margin: 0;
	display: flex;
	align-items: center;
	margin-top: 157px;
}

.H-Home-Collection-Outer .link-icon {
	width: 7px;
	height: 11px;
	object-fit: contain;
	margin-left: 13px;
}


.H-Home-Collection .outer-container {
	margin-top: 35px;
	flex: 0 0 auto;
	height: 402px;
	display: grid;
	grid-template-columns: repeat(auto-fill, 1fr);
	grid-template-rows: 402px;
	grid-column-gap: 100px;
	-webkit-column-gap: 100px;
	        column-gap: 100px;
}

.H-Home-Collection .item {
	grid-row: 1/2;
	width: 275px;
	height: 402px;
	background-color: white;
	border-radius: 10px;
}

.H-Home-Collection .item:first-of-type {
	margin-left: calc((100vw - 1400px) / 2);
}

.H-Home-Collection .item:last-of-type {
	margin-right: calc((100vw - 1400px) / 2);
}

/* INFOS */
.H-Home-Collection .item-inner-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
}

.H-Home-Collection .image-container {
	width: 275px;
	height: 275px;
}

.H-Home-Collection .image-container img {
	width: calc(275px - 100px);
	height: calc(275px - 100px);
	object-fit: contain;
	margin: 55px;
}

.H-Home-Collection .brand {
	font-size: 12px;
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	margin-top: 23px;
	opacity: 0.45;
}

.H-Home-Collection .title {
	font-size: 14px;
	text-align: center;
	font-weight: 500;
	text-overflow: ellipsis; 
	overflow: hidden; 
	white-space: nowrap;
	width: calc(275px - 60px);
	margin: 0;
	margin-top: 6px;
	opacity: 0.76;
}

.H-Home-Collection .price {
	margin: 0;
	margin-top: 15px;
	letter-spacing: -0.3px;
}

.H-Home-Collection .sale-price {
	margin: 0;
	font-size: 16px;
	letter-spacing: -0.3px;
	font-weight: 600;
	background-image: linear-gradient(133deg, #89C7EF 0%, #4A90E2 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.H-Home-Collection .original-price {
	font-size: 13px;
	color: #202020;
	opacity: 0.32;
	text-decoration: line-through;
	margin-left: 8px;
	opacity: 0.35;
}



@media screen and (min-width: 0px) and (max-width: 1600px) {
	.H-Home-Collection .outer-container {
		height: 380px;
		grid-template-rows: 380px;
		padding: 0 100px 0 100px;
		grid-column-gap: 100px;
		-webkit-column-gap: 100px;
		        column-gap: 100px;
	}

	.H-Home-Collection .item {
		width: 260px;
		height: 380px;
	}

	.H-Home-Collection .item:first-of-type {
		margin-left: 0;
	}
	
	.H-Home-Collection .item:last-of-type {
		margin-right: 0;
	}

	.H-Home-Collection-Outer .newest {
		margin-left: 100px;
	}

	.H-Home-Collection .image-container {
		width: 260px;
		height: 260px;
	}
	
	.H-Home-Collection .image-container img {
		width: calc(260px - 60px);
		height: calc(260px - 60px);
		margin: 30px;
	}
	
	.H-Home-Collection .brand {
		/* font-size: 11px; */
		margin-top: 5px;
	}
	
	.H-Home-Collection .title {
		width: calc(260px - 60px);
	}

	.H-Home-Collection .price {
		margin-top: 19px;
	}

	.H-Home-Collection .original-price {
	}

	/* NAV */
	.H-Home-Collection-Outer .nav-arrrow {
		width: 70px;
		height: 70px;
		border-radius: 35px;
		margin-top: calc(380px / 2 + 35px - 35px);
	}
}




@media screen and (min-width: 0px) and (max-width: 1200px) {
	.H-Home-Collection .outer-container {
		padding: 0 63px 0 63px;
		grid-column-gap: 63px;
		-webkit-column-gap: 63px;
		        column-gap: 63px;
	}

	.H-Home-Collection-Outer .newest {
		margin-left: 63px;
	}
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
	.H-Home-Collection-Outer .newest-link {
		margin-top: 100px;
	}

	.H-Home-Collection .outer-container {
		height: 300px;
		grid-template-rows: 300px;
		padding: 0 30px 0 30px;
		grid-column-gap: 30px;
		-webkit-column-gap: 30px;
		        column-gap: 30px;
	}

	.H-Home-Collection .item {
		width: 190px;
		height: 300px;
	}

	.H-Home-Collection .item:first-of-type {
		margin-left: 0;
	}
	
	.H-Home-Collection .item:last-of-type {
		margin-right: 0;
	}

	.H-Home-Collection-Outer .newest {
		margin-left: 30px;
	}

	.H-Home-Collection .image-container {
		width: 190px;
		height: 190px;
	}
	
	.H-Home-Collection .image-container img {
		width: calc(190px - 44px);
		height: calc(190px - 44px);
		margin: 22px;
	}
	
	.H-Home-Collection .brand {
		/* font-size: 11px; */
		margin-top: 0px;
		font-size: 12px;
	}
	
	.H-Home-Collection .title {
		width: calc(190px - 44px);
		margin-top: 2px;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;	 
		white-space: normal;
	}

	.H-Home-Collection .price {
		margin-top: 12px;
	}

	.H-Home-Collection .sale-price {
		font-size: 15px;
	}

	.H-Home-Collection .original-price {
		font-size: 11px;
	}

	/* NAV */
	.H-Home-Collection-Outer .nav-arrrow {
		width: 70px;
		height: 70px;
		border-radius: 35px;
		margin-top: calc(300px / 2 + 35px - 35px);
	}
}


@media screen and (min-width: 0px) and (max-width: 600px) {
	.H-Home-Collection-Outer .newest-link {
		margin-top: 50px;
	}

	.H-Home-Collection .outer-container {
		height: 238px;
		grid-template-rows: 238px;
		padding: 0 16px 0 16px;
		grid-column-gap: 16px;
		-webkit-column-gap: 16px;
		        column-gap: 16px;
	}

	.H-Home-Collection .item {
		width: 151px;
		height: 238px;
	}

	.H-Home-Collection .item:first-of-type {
		margin-left: 0;
	}
	
	.H-Home-Collection .item:last-of-type {
		margin-right: 0;
	}

	.H-Home-Collection-Outer .newest {
		margin-left: 16px;
	}

	.H-Home-Collection .image-container {
		width: 151px;
		height: 151px;
	}
	
	.H-Home-Collection .image-container img {
		width: calc(151px - 36px);
		height: calc(151px - 36px);
		margin: 18px;
	}
	
	.H-Home-Collection .brand {
		/* font-size: 11px; */
		margin-top: 0px;
		font-size: 9px;
	}
	
	.H-Home-Collection .title {
		width: calc(151px - 36px);
		margin-top: 1px;
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;	 
		white-space: normal;
	}

	.H-Home-Collection .price {
		margin-top: 8px;
	}

	.H-Home-Collection .sale-price {
		font-size: 12px;
	}

	.H-Home-Collection .original-price {
		font-size: 10px;
	}

	/* NAV */
	.H-Home-Collection-Outer .nav-right {
		left: 40px;
	}
	
	.H-Home-Collection-Outer .nav-left {
		right: 40px;
	}

	.H-Home-Collection-Outer .nav-arrrow {
		width: 50px;
		height: 50px;
		border-radius: 25px;
		margin-top: calc(238px / 2 + 35px - 35px);
	}

	.H-Home-Collection-Outer .nav-arrrow img {
		width: 10px;
		/* height: 24px; */
		object-fit: contain;
		opacity: 0;
	}
	
	.H-Home-Collection-Outer:hover .nav-arrrow img  {
		opacity: 0.7;
	}
	
}
.CategorySection {
	height: 525px;
	background-size: cover;
	background-position-y: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	margin-top: 170px;
	position: relative;
}

.CategorySection h3 {
	color: white;
	font-size: 30px;
	font-weight: 700;
	z-index: 3;
	padding: 0 20px;
	text-align: center;
}

.CategorySection .bg-image {
	position: absolute;
	width: 100vw;
	height: 100%;
	object-fit: cover;
	z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 1200px) {
	.CategorySection {
		height: 425px;
	}
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
	.CategorySection {
		height: 325px;
		margin-top: 150px;
	}
}

@media screen and (min-width: 0px) and (max-width: 600px) {
	.CategorySection {
		height: 190px;
		/* margin-top: 51px; */
		margin-top: 90px;
	}

	.app-banner {
		height: 260px;
	}

	.CategorySection h3 {
		color: white;
		font-size: 20px;
	}
}
.Footer {
	background-color: white;
	width: 100vw;
	height: 138px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: 0;
	z-index: 1;
}

.Footer .link-container {
	margin-left: 63px;
}

.Footer .link {
	opacity: 0.84;
	font-size: 10px;
	color: #202020;
	font-weight: 600;
	margin-right: 20px;
}

.Footer .instagram {
	background-image: url(/static/media/Instagram-Icon.e2d0f715.svg);
	background-size: contain;
	width: 22px;
	height: 22px;
	opacity: 0.84;
	margin-right: 63px;
}
.ProductCollection {
	/* min-height: 100vh; */
	position: relative;
	min-height: 100vh;
	padding-bottom: 250px;
}

.ProductCollection .background {
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	background-color: red;
	z-index: 0;
}


.ProductCollection .centering-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
  

.ProductCollection .outer-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 100px;
	-webkit-column-gap: 100px;
	        column-gap: 100px;
	grid-row-gap: 100px;
	row-gap: 100px;
	max-width: 1400px;
	margin-top: 60px;
	z-index: 1;
}

.ProductCollection .item {
	width: 275px;
	height: 402px;
	background-color: white;
	border-radius: 10px;
	z-index: 1;
}

/* INFOS */
.ProductCollection .item-inner-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ProductCollection .image-container {
	width: 275px;
	height: 275px;
}

.ProductCollection .image-container img {
	width: calc(275px - 100px);
	height: calc(275px - 100px);
	object-fit: contain;
	margin: 55px;
}

.ProductCollection .item-inner-container .brand {
	font-size: 12px;
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	/* margin-top: 23px; */
	opacity: 0.45;
	width: calc(275px - 60px);
	-webkit-line-clamp: 0;
	text-overflow: ellipsis; 
	overflow: hidden; 
	white-space: nowrap;
}

.ProductCollection .item-inner-container .title {
	font-size: 14px;
	text-align: center;
	font-weight: 500;
	/* text-overflow: ellipsis;  */
	/* overflow: hidden; 
	white-space: nowrap; */
	width: calc(275px - 60px);
	margin: 0;
	margin-top: 6px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}



.ProductCollection .item-inner-container .sale-price {
	margin: 0;
	margin-top: 15px;
	font-size: 16px;
	letter-spacing: -0.3px;
	background-image: linear-gradient(133deg, #89C7EF 0%, #4A90E2 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 600;
}

.ProductCollection .item-inner-container .original-price {
	font-size: 13px;
	color: #202020;
	text-decoration: line-through;
	margin-left: 4px;
	opacity: 0.75;
}

.ProductCollection .continue {
	background-color: white;
	width: 262px;
	height: 50px;
	color: #202020;
	font-size: 12px;
	font-weight: 700;
	border-radius: 8px;
	margin-top: 110px;
	transition: 0.2s;
}

.ProductCollection .continue-loading {
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0);
}

.ProductCollection .continue-end {
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0);
	color: rgba(0, 0, 0, 0);
}

.ProductCollection .search-value-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 180px;
}

.ProductCollection .search-value-container .title {
	font-size: 13px;
	font-weight: 600;
	margin: 0;
}

.ProductCollection .search-value-container .value {
	font-size: 20px;
	font-weight: 700;
	margin: 7px 0 0 0;
	text-transform: uppercase;
}

.ProductCollection .loading-text {
	font-size: 13px;
	font-weight: 700;
}


@media screen and (min-width: 0px) and (max-width: 1600px) {
	.ProductCollection .outer-container {
		/* padding: 0 100px 0 100px; */
		grid-column-gap: 6.3vw;
		-webkit-column-gap: 6.3vw;
		        column-gap: 6.3vw;
		grid-row-gap: 6.3vw;
		row-gap: 6.3vw;
	}

	.ProductCollection .item {
		width: 17.2vw;
		height: calc(17.2vw + 118px);
	}

	.ProductCollection .image-container {
		width: 17.2vw;
		height: 17.2vw;
	}
	
	.ProductCollection .image-container img {
		width: calc(17.2vw - 60px);
		height: calc(17.2vw - 60px);
		margin: 30px;
	}
	
	.ProductCollection .item-inner-container .brand {
		/* font-size: 11px; */
		margin-top: 5px;
		width: calc(17.2vw - 60px);
	}
	
	.ProductCollection .item-inner-container .title {
		width: calc(17.2vw - 60px);
	}

	.ProductCollection .item-inner-container .sale-price {
		margin-top: 19px;
	}
}

@media screen and (min-width: 0px) and (max-width: 1250px) {
	.ProductCollection .outer-container {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 9vw;
		-webkit-column-gap: 9vw;
		        column-gap: 9vw;
		grid-row-gap: 9vw;
		row-gap: 9vw;
	}

	.ProductCollection .search-value-container {
		margin-top: 150px;
	}

	.ProductCollection .item {
		width: calc((100vw - 9vw * 4) / 3);
		height: calc((100vw - 9vw * 4) / 3 + 118px);
	}

	.ProductCollection .item {
		width: calc((100vw - 9vw * 4) / 3);
		height: calc((100vw - 9vw * 4) / 3 + 118px);
	}

	.ProductCollection .image-container {
		width: calc((100vw - 9vw * 4) / 3);
		height: calc((100vw - 9vw * 4) / 3);
	}
	
	.ProductCollection .image-container img {
		width: calc(calc((100vw - 9vw * 4) / 3) - 60px);
		height: calc(calc((100vw - 9vw * 4) / 3) - 60px);
	}
	
	.ProductCollection .item-inner-container .brand {
		/* font-size: 11px; */
		margin-top: 5px;
		width: calc(calc((100vw - 9vw * 4) / 3) - 60px);
	}
	
	.ProductCollection .item-inner-container .title {
		width: calc(calc((100vw - 9vw * 4) / 3) - 60px);
	}

	.ProductCollection .item-inner-container .sale-price {
		margin-top: 19px;
	}
}

@media screen and (min-width: 0px) and (max-width: 980px) {
	.ProductCollection .search-value-container {
		margin-top: 130px;
	}

	.ProductCollection .outer-container {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 5.6vw;
		-webkit-column-gap: 5.6vw;
		        column-gap: 5.6vw;
		grid-row-gap: 5.6vw;
		row-gap: 5.6vw;
	}

	.ProductCollection .item {
		width: calc((100vw - 5.6vw * 4) / 3);
		height: calc((100vw - 5.6vw * 4) / 3 + 118px);
	}

	.ProductCollection .item {
		width: calc((100vw - 5.6vw * 4) / 3);
		height: calc((100vw - 5.6vw * 4) / 3 + 108px);
	}

	.ProductCollection .image-container {
		width: calc((100vw - 5.6vw * 4) / 3);
		height: calc((100vw - 5.6vw * 4) / 3);
	}
	
	.ProductCollection .image-container img {
		width: calc(calc((100vw - 5.6vw * 4) / 3) - 60px);
		height: calc(calc((100vw - 5.6vw * 4) / 3) - 60px);
		margin: 30px;
	}
	
	.ProductCollection .item-inner-container .brand {
		font-size: 10px;
		margin-top: 3px;
		width: calc(calc((100vw - 5.6vw * 4) / 3) - 60px);
	}
	
	.ProductCollection .item-inner-container .title {
		width: calc(calc((100vw - 5.6vw * 4) / 3) - 60px);
	}

	.ProductCollection .item-inner-container .sale-price {
		margin-top: 12px;
		font-size: 13px;
	}

	.ProductCollection .item-inner-container .original-price {
		font-size: 11px;
	}
}

@media screen and (min-width: 0px) and (max-width: 700px) {
	.ProductCollection {
		padding-bottom: 190px;
	}
	.ProductCollection .outer-container {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 5.6vw;
		-webkit-column-gap: 5.6vw;
		        column-gap: 5.6vw;
		grid-row-gap: 5.6vw;
		row-gap: 5.6vw;
		margin-top: 40px;
	}

	.ProductCollection .item {
		width: calc((100vw - 5.6vw * 3) / 2);	
		height: calc((100vw - 5.6vw * 3) / 2 + 108px);
	}

	.ProductCollection .image-container {
		width: calc((100vw - 5.6vw * 3) / 2);
		height: calc((100vw - 5.6vw * 3) / 2);
	}
	
	.ProductCollection .image-container img {
		width: calc(calc((100vw - 5.6vw * 3) / 2) - 80px);
		height: calc(calc((100vw - 5.6vw * 3) / 2) - 80px);
		margin: 40px;
	}
	
	.ProductCollection .item-inner-container .brand {
		font-size: 10px;
		margin-top: 3px;
		width: calc(calc((100vw - 5.6vw * 3) / 2) - 40px);
	}
	
	.ProductCollection .item-inner-container .title {
		width: calc(calc((100vw - 5.6vw * 3) / 2) - 40px);
	}

	.ProductCollection .item-inner-container .sale-price {
		margin-top: 12px;
		font-size: 15px;
	}

	.ProductCollection .item-inner-container .original-price {
		font-size: 12px;
	}

	.ProductCollection .continue {
		margin-top: 50px;
	}
}
.Filter .side-container {
	background-color: white;
	width: 410px;
	height: 100vh;
	position: fixed;
	left: 0; top: 0;
	z-index: 100;
	/* transition: left 0.3s ease-out; */
}

.Filter .category-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/* width: fit-content; */
	padding-top: 121px;
	padding-left: 55px;
}

.Filter .gender-c {
	padding-top: 60px;
}

.Filter .category-container button {
	background: rgba(0, 0, 0, 0);
	margin-bottom: 30px;
	display: flex;
	align-items: center;
}

.Filter .category-container p {
	margin: 0;
	font-weight: 600;
	font: 15px;
	margin-left: 13px;
}

.Filter .select-cat {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


.Filter .overlay {
	background-color: rgba(1, 1, 1, 1);
	width: 100vw;
	height: 100vh;
	z-index: 99;
	position: fixed;
	top: 0;
}


@media screen and (min-width: 0px) and (max-width: 500px) {
	.Filter .side-container {
		width: 320px;
	}
}

@media screen and (min-width: 0px) and (max-width: 300px) {
	.Filter .side-container {
		width: 220px;
	}
}

@media screen and (min-width: 0px) and (max-width: 250px) {
	.Filter .side-container {
		width: 100px;
	}
}
.OptionContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 180px;
	max-width: 1400px;
	width: calc(100vw - (6.3vw * 2));
}

.OptionContainer .filter-container {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);
}

.OptionContainer .filter-container img {
	width: 20px;
	height: 16px;
	object-fit: contain;
}

.OptionContainer .filter-container p {
	margin: 0;
	margin-left: 13px;
	font-weight: 700;
	font-size: 13px;
	color: #737474;
}

.OptionContainer .sortBy-container {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);	
}

.OptionContainer .sortBy-container p {
	margin: 0;
	margin-right: 13px;
	font-weight: 700;
	font-size: 13px;
	color: #737474;
}

.OptionContainer .sortBy-container select {
	width: 132px;
	height: 40px;
	background-color: white;
	border-radius: 8px;
	/* -webkit-appearance: none;
   	-moz-appearance: none;
   	appearance: none; */
	text-align: center;
	text-align-last: center;
	-moz-text-align-last: center;
	font-size: 11px;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	color: #737474;
}

.OptionContainer .sortBy-container select:focus {
	outline: none
  }



@media screen and (min-width: 0px) and (max-width: 1250px) {
	.OptionContainer {
		width: calc(100vw - (9vw * 2));
	}
}

@media screen and (min-width: 0px) and (max-width: 980px) {
	.OptionContainer {
		width: calc(100vw - (6.3vw * 2));
	}
}

@media screen and (min-width: 0px) and (max-width: 700px) {
	.OptionContainer {
		margin-top: 130px;
		width: calc(100vw - (5.6vw * 2));
	}
}
.product-page {
	margin: 0;
	position: relative;
	min-height: 100vh;
	padding-bottom: 300px;
}

.product-page .content-split {
	position: relative;
	/* min-height: 100vh; */
}


/* CONTENT */
.product-page .product-info-container {
	box-sizing:border-box;
	width: calc(100vw / 2);
	/* min-height: 100vh; */
	margin-left: calc(100vw / 2);
	max-width: calc(100vw / 2);
	padding: 230px 150px 0 150px;
	/* margin-top: calc(100vh / 2 - 128px - (369px / 2));  */
}

.product-page .product-info-container .brand {
	margin: 0;
	font-weight: 700;
	opacity: 0.5;
	font-size: 13px;
	text-transform: uppercase;
	margin-top: 76px;
}

.product-page .product-info-container .title {
	margin: 10px 0 0 0;
	font-weight: 800;
	font-size: 22px;
}

.product-page .product-info-container .price {
	margin: 44px 0 0 0;
	font-weight: 700;
}

.product-page .product-info-container .sale-price {
	font-size: 24px;
	background-image: linear-gradient(133deg, #7EBAE8 0%, #4A90E2 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.product-page .product-info-container .original-price {
	margin-left: 10px;
	font-size: 13px;
	text-decoration: line-through;
	opacity: 0.5;
}

.product-page .product-info-container .deal-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 220px;
	height: 48px;
	background: rgb(154,182,243);
	background: linear-gradient(133deg, #89C7EF 0%, #4A90E2 100%);
	color: white;
	font-weight: 700;
	font-size: 13px;
	margin-top: 25px;
	border-radius: 13px;
	transition: 0.25s;
}

.product-page .product-info-container .deal-button p {
	width: 220px;
	height: 48px;
	background: rgb(154,182,243);
	background: linear-gradient(133deg, rgba(154,182,243,1) 0%, rgba(97,127,227,1) 100%);
	color: white;
	font-weight: 700;
	font-size: 13px;
	border-radius: 13px;
	transition: 0.25s;
}

.product-page .product-info-container .deal-button:hover {
	/* border-radius: 0px; */
	letter-spacing: 2px;
}

.product-page .product-info-container .seperate-line {
	background-color: white;
	margin: 100px 0;
	width: calc(100vw / 2);
	height: 3px;
	margin-left: -150px;
}

/* SHOP INFO */
.product-page .info-wrapper {
	display: inline-flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
}

.product-page .info-v-stack .title {
	font-weight: 600;
	font-size: 10px;
	letter-spacing: 2.33px;
	margin: 0 0 4px 0;
}

.product-page .info-v-stack .name {
	font-weight: 700;
	font-size: 14px;
	margin: 0;
	text-transform: uppercase;
}

/* DESCRIPTION */
.product-page .product-info-container .description {
	opacity: 0.4;
	font-size: 15px;
	line-height: 22px;
}

/* SELLING POINTS */
.product-page .product-info-container .selling-point-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.product-page .product-info-container .dot {
	background-color: #202020;
	opacity: 0.4;
	width: 5px;
	height: 5px;
	border-radius: 2.5px;
}

.product-page .product-info-container .selling-point-wrapper p {
	opacity: 0.4;
	font-size: 15px;
	/* line-height: 22px; */
	margin: 8px 0 8px 10px;
}


/* IMAGE */
.product-page .image-container {
	width: calc(100vw / 2);
	height: 100vh;
	background-color: white;
	position: fixed;
	/* margin-top: 0;
	margin-left: 0; */
	left: 0; top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-page .image-container img {
	width: calc(100vw / 2 - 400px);
	height: calc(100vw / 2 - 400px);
	max-height: calc(100vh - 600px);
	min-height: 300px;
	object-fit: contain;
}


@media screen and (min-width: 0px) and (max-width: 1950px) {
	.product-page .product-info-container {
		padding: 230px 100px 0 100px;
		/* padding-top: calc(100vh / 2 - (369px / 2)); */
	}

	.product-page .product-info-container .seperate-line {
		margin-left: -100px;
	}
}

@media screen and (min-width: 0px) and (max-width: 1450px) {
	.product-page .product-info-container {
		padding: 230px 60px 0 60px;
		/* padding-top: calc(100vh / 2 - (369px / 2)); */
	}

	.product-page .product-info-container .seperate-line {
		margin-left: -60px;
	}
}


@media screen and (min-width: 0px) and (max-width: 1080px) {
	.product-page .content-split {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.product-page .product-info-container {
		box-sizing: border-box;
		width: 100vw;
		max-width: 100vw;
		padding: 0px 100px 0 100px;
		margin-left: 0;
		/* margin-top: calc(100vh / 2 - 128px - (369px / 2)); */
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.product-page .product-info-container .title {
		text-align: center;
	}

	.product-page .product-info-container .seperate-line {
		margin-left: 0px;
	}

	.product-page .product-info-container .selling-point-wrapper p {
		text-align: center;
		margin-left: 0;
	}

	.product-page .product-info-container .dot {
		display: none;
	}

	/* IMAGE */
	.product-page .image-container {
		width: 100vw;
		height: 591px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 128px;
	}

	.product-page .image-container img {
		width: calc(100vw - 100px);
		height: calc(100vw - 100px);
		max-height: 391px;
		min-height: 300px;
	}

	/*  */
	.product-page .info-section {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	/* SHOP INFO */
	.product-page .info-wrapper {
		display: inline-flex;
		flex-direction: column;
		grid-gap: 24px;
		gap: 24px;
		align-items: center;
	}

	/* DESCRIPTION */
	.product-page .product-info-container .description {
		opacity: 0.4;
		font-size: 15px;
		line-height: 22px;
		text-align: center;
	}
}


@media screen and (min-width: 0px) and (max-width: 760px) {
	.product-page {
		padding-bottom: 200px;
	}

	.product-page .product-info-container {
		padding: 0px 30px 0 30px;
		/* padding-top: calc(100vh / 2 - (369px / 2)); */
	}

	/* IMAGE */
	.product-page .image-container {
		width: 100vw;
		height: 100vw;
		max-height: 400px;
		/* padding-top: 128px; */
	}

	.product-page .image-container img {
		width: calc(100vw - 100px);
		height: calc(100vw - 100px);
		max-height: 300px;
		min-height: 20px;
	}

	/*  */
	.product-page .product-info-container .brand {
		margin-top: 56px;
	}

	/*  */
	.product-page .product-info-container .seperate-line {
		margin: 80px 0;
		width: calc(100vw / 2);
	}
}
.contact {
	min-height: 100vh;
	position: relative;
}

.contact .contact-wrapper {
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.contact h1 {
	margin: 0;
	font-weight: 700;
	font-size: 19px;
	margin-top: 50px;
}

.contact a {
	margin: 0;
	font-weight: 700;
	font-size: 12px;
	margin-top: 15px;
}

@media screen and (min-width: 0px) and (max-width: 1080px) {
	.contact h1 {
		margin-top: 150px;
	}
}

@media screen and (min-width: 0px) and (max-width: 600px) {
	.contact h1 {
		margin-top: 150px;
	}
}
.impressum {
	position: relative;
	min-height: 100vh;
	padding-bottom: 250px;
}

.impressum .wrapper {
	/* display: flex;
	justify-content: center; */
	/* width: 100vw; */
	margin: 0 90px;
	margin-top: 70px;
}

.impressum .infos-wrapper {
	display: flex;
	align-items: center;
	/* margin: 100px; */
	width: calc(100vw - 200px);
	max-width: 500px;
	background-color: red;
}

.impressum .title {
	font-size: 17px;
	font-weight: 700;
	margin: 0;
	margin-top: 80px;
	margin-bottom: 30px;
}

.impressum .low {
	margin-top: 40px;
}

.impressum .content {
	font-size: 14px;
	font-weight: 600;
	margin-top: 9px;
	opacity: 0.5;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
	.impressum {
		margin-top: 180px;
		padding-bottom: 290px;
	}
}

@media screen and (min-width: 0px) and (max-width: 600px) {
	.impressum {
		margin-top: 130px;
		padding-bottom: 230px;
	}

	.impressum .wrapper {
		margin: 0 30px;
		margin-top: 70px;
	}
}
.Datenschutz {
	position: relative;
	min-height: 100vh;
	padding-bottom: 90px;
}

.Datenschutz .container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Datenschutz .container .wrapper {
	padding: 70px 0 190px 0;
	min-height: 100vh;
	width: 60vw;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.Datenschutz .container h1 {
	font-weight: 800;
	font-size: 30px;
	text-align: left;
}

.Datenschutz .container h2 {
	font-weight: 700;
	font-size: 25px;
	text-align: left;
	margin-top: 90px;
}

.Datenschutz .container h3 {
	font-weight: 700;
	font-size: 20px;
	text-align: left;
	margin-top: 16px;
	margin-bottom: 5px;
}

.Datenschutz .container p {
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	text-transform: none;
}

.Datenschutz ul {
	list-style-position: inside;
	font-size: 14px;
}

@media only screen and (max-width: 1600px) {
	.Datenschutz .container .wrapper  {
		width: 75vw;
	}
}

@media only screen and (max-width: 1450px) {
	.Datenschutz .container .wrapper  {
		width: 84vw;
	}
}


/* ___________ TABLET ___________ */
@media only screen and (max-width: 1100px) {
	.Datenschutz .container .wrapper  {
		min-height: 100vh;
		width: 100vw;
		padding: 140px 50px 150px 50px;
	}

	.Datenschutz .container h1 {
		font-size: 25px;
	}

	.Datenschutz .container h2 {
		font-size: 22px;
	}

	.Datenschutz .container h3 {
		font-size: 18px;
	}
}

@media only screen and (max-width: 750px) {
	.Datenschutz .container .wrapper  {
		padding: 100px 30px 150px 30px;
	}


	.Datenschutz .container h1 {
		font-size: 23px;
	}

	.Datenschutz .container h2 {
		font-size: 20px;
	}

	.Datenschutz .container h3 {
		font-size: 16px;
		margin-top: 50px;
		margin-bottom: 5px;
	}

	.Datenschutz .container p {
		font-size: 14px;
		width: 100%;
		line-height: 20px;
	}


	.Datenschutz .container a {
		color: #3FA1EC;
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;
	
		-ms-word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;
	
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

}
.Page404 {
	position: relative;
	min-height: 100vh;
}

.Page404 .wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.Page404 h1  {
	font-weight: 700;
	font-size: 25px;
	opacity: 0.9;
	margin-top: 90px;
}

.Page404 h1 .code {
	font-weight: 500;
	font-size: 40px;
}

.Page404 h2 {
	font-weight: 500;
	font-size: 15px;
	opacity: 0.6;
	text-align: center;
}

.Page404 .back {
	background-color: #202020;
	color: white;
	width: 300px;
	height: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 11px;
	font-size: 15px;
	font-weight: 700;
	margin-top: 50px;
}

@media only screen and (max-width: 750px) {
	.Page404 .wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 0 30px;
	}

	.Page404 h1  {
		margin-top: 150px;
	}

	.Page404 .back {
		background-color: #202020;
		color: white;
		width: 200px;
	}
}

