a {
	cursor: pointer;
}
.HomeHeader {
	/* position: fixed; */
	z-index: 90;
	top: 0;
}

.HomeHeader .header-container {
	margin: 0;
	width: 100vw;
	padding: 43px 0;
	z-index: 99;
	position: relative;
	/* background-color: rgba(255, 255, 255, 0); */
}

/*  */
.HomeHeader .title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.HomeHeader .title-container img {
	object-fit: contain;
	width: 33px;
}

.HomeHeader .title-container h3 {
	margin: 0;
	font-size: 13px;
	margin-left: 15px;
	background-image: linear-gradient(-134deg, #467BE4 0%, #1F6CCE 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}


.HomeHeader .bottom-container {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100vw - 126px);
	/* margin-top: 26px; */
	margin-left: 63px;
}


/* LOGO */
.HomeHeader .nav-logo-wrapper {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 95;
}



/* LINKS */
.HomeHeader .links-container {
	display: inline-flex;
	align-items: center;
	gap: 12px;
	z-index: 96;
}

/* The container <div> - needed to position the dropdown content */
.HomeHeader .dropdown {
	position: relative;
	display: inline-block;
}

.HomeHeader .dropdown-cover-content {
	padding-top: 15px;
	display: none;
	position: absolute;
	min-width: 160px;
	left: 51%;
	transform: translateX(-51%);
}

.HomeHeader .dropdown-h-container {
	display: flex;
	border-radius: 13px;
	background-color: white;
}
  
.HomeHeader .dropdown-content {
	background-color: white;
	min-width: 160px;
	z-index: 100;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 13px;
	padding: 35px 0;
}

.HomeHeader .dropdown-content .gender-title {
	font-size: 10px;
	font-weight: 700;
	margin: 0;
	margin-bottom: 20px;
}

.HomeHeader .subLink {
	cursor: pointer;
	background-color: rgb(0, 0, 0, 0);
}

.HomeHeader .dropdown-content .subLink p {
	margin: 0 0 17px 0;
	font-size: 12px;
	font-weight: 700;
	/* margin-bottom: 17px; */
	opacity: 0.42;
	/* transition: 0.25s; */
	color: #282828;
}

.HomeHeader .dropdown-content .subLink:last-child p { margin-bottom: 0px;}
.HomeHeader .dropdown-content .subLink:hover p { opacity: 1}

/* Show the dropdown menu on hover */
.HomeHeader .dropdown:hover .dropdown-cover-content {display: block;}

.HomeHeader .links-container .link {
	height: fit-content;
	font-size: 11px;
	font-weight: 700;
	border-radius: 11px;
}

.HomeHeader .links-container .link p {
	margin: 0;
	color: rgb(32, 32, 32, 0.4);
	padding: 14px 18.5px;
	border-radius: 11px;
	transition: ease-out 0.3s;
	text-transform: uppercase;
}

.HomeHeader .dropdown:hover .link p {
	color: rgb(32, 32, 32, 1);
	background-color: white;
}



/* Search */
/* INPUT */
.HomeHeader .link-input {
	border: 0;
	background-color: rgb(255, 255, 255, 0.7);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	height: 40px;
	width: 280px;
	border-radius: 9px;
	text-align: center;
	padding: 0 16px;
	font-weight: 600;
	font-size: 14px;
	color: #282828;
	font-family: 'Montserrat', sans-serif;
	z-index: 97;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ABABAB;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #ABABAB;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: #ABABAB;
 }

.HomeHeader input:focus {
	outline: none;
}




/* Icon Left */
.HomeHeader .DA-icon-left {
	object-fit: contain;
	width: 46px;
	height: 49px;
	margin-right: 40px;
}



.HomeHeader .toggler {
	cursor: pointer;
	appearance: none;
	background-color: transparent;
	border: 0 none;
	color: #fff;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	outline: 0;
	position: relative;
	transition: ease 200ms color;
	z-index: 1000;
	width: 25px;
	height: 14px;
  }
  
.HomeHeader .path {
	/* stroke: currentColor; */
	background-color: #282828;
	width: 25px;
	height: 4px;
	border-radius: 2px;
}



/* Mobile Search */
.HomeHeader .search-icon-button {
	z-index: 99;
	background-color: rgb(0, 0, 0, 0);
}

.HomeHeader .search-mobile-container {
	background-color: white;
	width: 100vw;
	height: 60px;
	display: flex;
	align-items: center;
	z-index: 80;
}

.HomeHeader .mobile-search-field {
	border: 0;
	background-color: #F2F2F2;
	height: 42px;
	width: calc(100vw - 15px - 90px);
	border-radius: 10px;
	padding: 0 13px;
	display: flex;
	align-items: center;
	margin: 0 10px 0 15px;
}

.HomeHeader .mobile-search-field img {
	width: 12.5px;
	height: 12.5px;
	object-fit: contain;
	opacity: 0.3;
}

.HomeHeader .mobile-search-input {
	border: 0;
	background-color: #F2F2F2;
	height: 42px;
	text-align: left;
	padding: 0 0 0 7px;
	font-weight: 500;
	font-size: 13px;
	color: #282828;
	font-family: 'Montserrat', sans-serif;
	z-index: 97;
	width: 100%;
}

.HomeHeader .cancel-search {
	font-size: 11px;
	margin-right: 15px;
	height: 100%;
	background-color: rgb(0, 0, 0, 0);
}



  @media screen and (min-width: 0px) and (max-width: 1080px) {
	  .link-input {
		  display: none;
	  }

	  .HomeHeader .bottom-container {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(100vw - 52px);
		/* margin-top: 26px; */
		margin-left: 27px;
		z-index: 90;
	}	
  }	