.ProductCollection {
	/* min-height: 100vh; */
	position: relative;
	min-height: 100vh;
	padding-bottom: 250px;
}

.ProductCollection .background {
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	background-color: red;
	z-index: 0;
}


.ProductCollection .centering-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
  

.ProductCollection .outer-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 100px;
	row-gap: 100px;
	max-width: 1400px;
	margin-top: 60px;
	z-index: 1;
}

.ProductCollection .item {
	width: 275px;
	height: 402px;
	background-color: white;
	border-radius: 10px;
	z-index: 1;
}

/* INFOS */
.ProductCollection .item-inner-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ProductCollection .image-container {
	width: 275px;
	height: 275px;
}

.ProductCollection .image-container img {
	width: calc(275px - 100px);
	height: calc(275px - 100px);
	object-fit: contain;
	margin: 55px;
}

.ProductCollection .item-inner-container .brand {
	font-size: 12px;
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	/* margin-top: 23px; */
	opacity: 0.45;
	width: calc(275px - 60px);
	-webkit-line-clamp: 0;
	text-overflow: ellipsis; 
	overflow: hidden; 
	white-space: nowrap;
}

.ProductCollection .item-inner-container .title {
	font-size: 14px;
	text-align: center;
	font-weight: 500;
	/* text-overflow: ellipsis;  */
	/* overflow: hidden; 
	white-space: nowrap; */
	width: calc(275px - 60px);
	margin: 0;
	margin-top: 6px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}



.ProductCollection .item-inner-container .sale-price {
	margin: 0;
	margin-top: 15px;
	font-size: 16px;
	letter-spacing: -0.3px;
	background-image: linear-gradient(133deg, #89C7EF 0%, #4A90E2 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 600;
}

.ProductCollection .item-inner-container .original-price {
	font-size: 13px;
	color: #202020;
	text-decoration: line-through;
	margin-left: 4px;
	opacity: 0.75;
}

.ProductCollection .continue {
	background-color: white;
	width: 262px;
	height: 50px;
	color: #202020;
	font-size: 12px;
	font-weight: 700;
	border-radius: 8px;
	margin-top: 110px;
	transition: 0.2s;
}

.ProductCollection .continue-loading {
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0);
}

.ProductCollection .continue-end {
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0);
	color: rgba(0, 0, 0, 0);
}

.ProductCollection .search-value-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 180px;
}

.ProductCollection .search-value-container .title {
	font-size: 13px;
	font-weight: 600;
	margin: 0;
}

.ProductCollection .search-value-container .value {
	font-size: 20px;
	font-weight: 700;
	margin: 7px 0 0 0;
	text-transform: uppercase;
}

.ProductCollection .loading-text {
	font-size: 13px;
	font-weight: 700;
}


@media screen and (min-width: 0px) and (max-width: 1600px) {
	.ProductCollection .outer-container {
		/* padding: 0 100px 0 100px; */
		column-gap: 6.3vw;
		row-gap: 6.3vw;
	}

	.ProductCollection .item {
		width: 17.2vw;
		height: calc(17.2vw + 118px);
	}

	.ProductCollection .image-container {
		width: 17.2vw;
		height: 17.2vw;
	}
	
	.ProductCollection .image-container img {
		width: calc(17.2vw - 60px);
		height: calc(17.2vw - 60px);
		margin: 30px;
	}
	
	.ProductCollection .item-inner-container .brand {
		/* font-size: 11px; */
		margin-top: 5px;
		width: calc(17.2vw - 60px);
	}
	
	.ProductCollection .item-inner-container .title {
		width: calc(17.2vw - 60px);
	}

	.ProductCollection .item-inner-container .sale-price {
		margin-top: 19px;
	}
}

@media screen and (min-width: 0px) and (max-width: 1250px) {
	.ProductCollection .outer-container {
		grid-template-columns: repeat(3, 1fr);
		column-gap: 9vw;
		row-gap: 9vw;
	}

	.ProductCollection .search-value-container {
		margin-top: 150px;
	}

	.ProductCollection .item {
		width: calc((100vw - 9vw * 4) / 3);
		height: calc((100vw - 9vw * 4) / 3 + 118px);
	}

	.ProductCollection .item {
		width: calc((100vw - 9vw * 4) / 3);
		height: calc((100vw - 9vw * 4) / 3 + 118px);
	}

	.ProductCollection .image-container {
		width: calc((100vw - 9vw * 4) / 3);
		height: calc((100vw - 9vw * 4) / 3);
	}
	
	.ProductCollection .image-container img {
		width: calc(calc((100vw - 9vw * 4) / 3) - 60px);
		height: calc(calc((100vw - 9vw * 4) / 3) - 60px);
	}
	
	.ProductCollection .item-inner-container .brand {
		/* font-size: 11px; */
		margin-top: 5px;
		width: calc(calc((100vw - 9vw * 4) / 3) - 60px);
	}
	
	.ProductCollection .item-inner-container .title {
		width: calc(calc((100vw - 9vw * 4) / 3) - 60px);
	}

	.ProductCollection .item-inner-container .sale-price {
		margin-top: 19px;
	}
}

@media screen and (min-width: 0px) and (max-width: 980px) {
	.ProductCollection .search-value-container {
		margin-top: 130px;
	}

	.ProductCollection .outer-container {
		grid-template-columns: repeat(3, 1fr);
		column-gap: 5.6vw;
		row-gap: 5.6vw;
	}

	.ProductCollection .item {
		width: calc((100vw - 5.6vw * 4) / 3);
		height: calc((100vw - 5.6vw * 4) / 3 + 118px);
	}

	.ProductCollection .item {
		width: calc((100vw - 5.6vw * 4) / 3);
		height: calc((100vw - 5.6vw * 4) / 3 + 108px);
	}

	.ProductCollection .image-container {
		width: calc((100vw - 5.6vw * 4) / 3);
		height: calc((100vw - 5.6vw * 4) / 3);
	}
	
	.ProductCollection .image-container img {
		width: calc(calc((100vw - 5.6vw * 4) / 3) - 60px);
		height: calc(calc((100vw - 5.6vw * 4) / 3) - 60px);
		margin: 30px;
	}
	
	.ProductCollection .item-inner-container .brand {
		font-size: 10px;
		margin-top: 3px;
		width: calc(calc((100vw - 5.6vw * 4) / 3) - 60px);
	}
	
	.ProductCollection .item-inner-container .title {
		width: calc(calc((100vw - 5.6vw * 4) / 3) - 60px);
	}

	.ProductCollection .item-inner-container .sale-price {
		margin-top: 12px;
		font-size: 13px;
	}

	.ProductCollection .item-inner-container .original-price {
		font-size: 11px;
	}
}

@media screen and (min-width: 0px) and (max-width: 700px) {
	.ProductCollection {
		padding-bottom: 190px;
	}
	.ProductCollection .outer-container {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 5.6vw;
		row-gap: 5.6vw;
		margin-top: 40px;
	}

	.ProductCollection .item {
		width: calc((100vw - 5.6vw * 3) / 2);	
		height: calc((100vw - 5.6vw * 3) / 2 + 108px);
	}

	.ProductCollection .image-container {
		width: calc((100vw - 5.6vw * 3) / 2);
		height: calc((100vw - 5.6vw * 3) / 2);
	}
	
	.ProductCollection .image-container img {
		width: calc(calc((100vw - 5.6vw * 3) / 2) - 80px);
		height: calc(calc((100vw - 5.6vw * 3) / 2) - 80px);
		margin: 40px;
	}
	
	.ProductCollection .item-inner-container .brand {
		font-size: 10px;
		margin-top: 3px;
		width: calc(calc((100vw - 5.6vw * 3) / 2) - 40px);
	}
	
	.ProductCollection .item-inner-container .title {
		width: calc(calc((100vw - 5.6vw * 3) / 2) - 40px);
	}

	.ProductCollection .item-inner-container .sale-price {
		margin-top: 12px;
		font-size: 15px;
	}

	.ProductCollection .item-inner-container .original-price {
		font-size: 12px;
	}

	.ProductCollection .continue {
		margin-top: 50px;
	}
}