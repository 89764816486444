.contact {
	min-height: 100vh;
	position: relative;
}

.contact .contact-wrapper {
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.contact h1 {
	margin: 0;
	font-weight: 700;
	font-size: 19px;
	margin-top: 50px;
}

.contact a {
	margin: 0;
	font-weight: 700;
	font-size: 12px;
	margin-top: 15px;
}

@media screen and (min-width: 0px) and (max-width: 1080px) {
	.contact h1 {
		margin-top: 150px;
	}
}

@media screen and (min-width: 0px) and (max-width: 600px) {
	.contact h1 {
		margin-top: 150px;
	}
}